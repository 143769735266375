import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateServerLoaderFactory } from '../shared/loaders/translate-server.loader';
import { TransferState } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
export const COMMON_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  TranslateModule,
  ReactiveFormsModule,
  // NoConsecutiveSpacesDirectiveModule,
];
export const ROOT_LEVEL_MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateServerLoaderFactory,
      deps: [TransferState],
    },
  }),
  ToastrModule.forRoot({
    preventDuplicates: true,
    autoDismiss: true,
    enableHtml: true,
    closeButton: true,
    maxOpened: 1,
    resetTimeoutOnDuplicate: true,
    progressBar: false,
    timeOut: 10 * 1000, // 15 milliseconds
    positionClass: 'toast-top-center',
    disableTimeOut: false,
    toastClass: 'senegal-toastr',
    messageClass: 'senegal-toastr-text',
    tapToDismiss: true,
  }),

];
