import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Creates a TranslateHttpLoader instance for the server-side translation.
 *
 * @param {HttpClient} http - The HttpClient instance for making HTTP requests.
 * @return {TranslateHttpLoader} The TranslateHttpLoader instance.
 */
export function translateServerLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
